import { FrontendLocale, validateFrontendLocaleOrFallbackToDefault } from "@libry-content/localization";
import { EventType } from "@libry-content/types";
import groq from "groq";
import { z } from "zod";
import {
  ResolvedLibrarySummary,
  resolveLibrarySummary,
} from "../../../../../apps/frontend/src/components/library/sanityQuery";
import { DateHelper } from "../../date/DateHelper";
import { TargetAudienceCode } from "../../targetAudiences";
import { eventsGroqQuery } from "./eventFilters";
import { ResolvedEventSummary, resolveEventSummaryGroqProjection } from "./resolveEvent";

export const eventsListUrlStateSchema = z
  .object({
    eventTypes: z.array(z.string()).optional(),
    libraries: z.array(z.string()).optional(),
    targetAudiences: z.array(z.string()).optional(),
    from: z.string().optional(),
    to: z.string().optional(),
    pagination: z.number().default(1).optional(),
  })
  .optional();

export type EventsListUrlState = z.infer<typeof eventsListUrlStateSchema>;

const eventsFacetsFilters = groq`[
  (!defined($eventTypes) || length($eventTypes) == 0 || eventType->label[$lang] in $eventTypes) &&
  (!defined($libraries) || length($libraries) == 0 || library->slug in $libraries) &&
  (!defined($targetAudiences) || length($targetAudiences) == 0 || array::intersects(targetAudiences, $targetAudiences))
]`;

export type FinishedOrUnfinishedEvents = "finished" | "unfinished";

export type EventsListGroqParams = {
  eventTypes: string[] | null;
  libraries: string[] | null;
  targetAudiences: string[] | null;
  lang: FrontendLocale;
  start: number;
  end: number;
  from: string | null;
  to: string | null;
};

export const eventsListPageSize = 12 as const; // Om denne skal settes høyere må vi legge til litt mer test-data i seed-datasettet for "test-mikromarc". De har pr nå bare 13 avholdte arrangementer og testen som ser etter "vis mer"-knappen vil feile.

export const getEventsListGroqParams = (
  urlState: EventsListUrlState,
  page: number,
  finishedOrUnfinished: FinishedOrUnfinishedEvents,
  locale: string
): EventsListGroqParams => ({
  start: (page - 1) * eventsListPageSize,
  end: page * eventsListPageSize,
  eventTypes: urlState?.eventTypes || null,
  libraries: urlState?.libraries || null,
  targetAudiences: urlState?.targetAudiences || null,
  from:
    (urlState?.from && DateHelper.isoStringFromTimeAndDate("00:00", urlState.from)) ||
    (finishedOrUnfinished === "unfinished" && new Date().toISOString()) ||
    null,
  to:
    (urlState?.to && DateHelper.isoStringFromTimeAndDate("23:59", urlState.to)) ||
    (finishedOrUnfinished === "finished" && new Date().toISOString()) ||
    null,
  lang: validateFrontendLocaleOrFallbackToDefault(locale),
});

export type EventsListResponse = {
  events: ResolvedEventSummary[];
  endOfResults: boolean;
  facets: EventsListFacets;
};

export type EventsListFacets = {
  libraries: ResolvedLibrarySummary[];
  eventTypes: EventType[];
  targetAudiences: TargetAudienceCode[];
};

export type EventFacetName = keyof EventsListFacets;

export const getEventsListQuery = (finishedOrUnfinished: FinishedOrUnfinishedEvents) => {
  const order = finishedOrUnfinished === "finished" ? "desc" : "asc";
  return groq`{ 
    "filteredEvents": ${eventsGroqQuery({
      from: "$from",
      to: "$to",
    })} ${eventsFacetsFilters},
  }
  {
    "events": @.filteredEvents | order(eventSchedule.startsAt ${order}) [$start...$end] ${resolveEventSummaryGroqProjection},
    "endOfResults": !defined(@.filteredEvents[$end]),
    "facets": {
      "libraries": UNSCOPED[_type == "library" && _id in array::unique(^.filteredEvents[].library._ref)]{${resolveLibrarySummary}},
      "eventTypes": UNSCOPED[_type == "eventType" && _id in array::unique(^.filteredEvents[].eventType._ref)],
      "targetAudiences": array::unique(@.filteredEvents[].targetAudiences[]) [defined(@)],
    }
  }`;
};
