import { seededRandomFromArray } from "@biblioteksentralen/utils";
import { createSitePalette } from "../colors";
import { DigitalLibraryService, isBibliofilAppService } from "../digitalLibraryService";
import { ResolvedSite } from "../groq";

export const getDigitalServiceColor = (props: {
  service?: Pick<DigitalLibraryService, "_id">;
  site?: ResolvedSite;
}) => {
  const palette = createSitePalette(props.site);
  const colors = [palette.colors.darkaccent1, palette.colors.darkaccent2, palette.colors.darkaccent4];

  if (!props.service) return colors[0];

  // The Bibliofil logo doesn't work with all colors
  if (isBibliofilAppService(props.service)) return palette.colors.lightaccent4;

  return seededRandomFromArray(colors, props.service._id);
};
