import { colors, seededRandom } from "@biblioteksentralen/utils";
import { CustomPalette } from "@libry-content/types";
import convert from "color-convert";
import { ResolvedSite } from "../groq";

export type ResolvedPaletteColor = {
  css: {
    background: string;
    color: string;
  };
  isBright: boolean;
};

export type ColorPaletteKeys = keyof Omit<CustomPalette, "_type" | "useCustomPalette">;

export type ColorPalette = { useCustomPalette: boolean; colors: Record<ColorPaletteKeys, ResolvedPaletteColor> };

const defaultColors: Record<ColorPaletteKeys, string> = {
  header: colors.white,
  search: colors.lightPurple,
  footer: colors.grey05,
  card: colors.grey10,
  pop1: colors.accentYellow,
  pop2: colors.accentBlueMedium,
  darkaccent1: colors.accentPurple,
  lightaccent1: colors.lightPurple,
  darkaccent2: colors.accentGreen,
  lightaccent2: colors.lightGreen,
  darkaccent3: colors.accentPeach,
  lightaccent3: colors.lightPeach,
  darkaccent4: colors.accentBlueDark,
  lightaccent4: colors.lightBlue,
};

const colorPaletteKeys = Object.keys(defaultColors) as ColorPaletteKeys[];

export const createSitePalette = (site?: ResolvedSite): ColorPalette => {
  const useCustomPalette = !!site?.customPalette?.useCustomPalette;

  const colors = colorPaletteKeys.reduce(
    (acc, key) => ({
      ...acc,
      [key]: getColorFor(key, useCustomPalette, site),
    }),
    {} as Record<ColorPaletteKeys, ResolvedPaletteColor>
  );

  return { colors, useCustomPalette };
};

const getColorFor = (key: ColorPaletteKeys, useCustomPalette: boolean, site?: ResolvedSite): ResolvedPaletteColor => {
  const color = (useCustomPalette && site?.customPalette?.[key]?.color) || defaultColors[key];

  const colorIsBright = isBright(color);

  return {
    css: {
      background: color,
      color: colorIsBright ? "gray.800" : "white",
    },
    isBright: colorIsBright,
  };
};

export function isBright(color: string) {
  if (!color.match(/^#[0-9,a,b,c,d,e,f]{6}$/i)) {
    console.error(`I only understand hex-colors, got: "${color}"`);
  }

  const lightness = convert.hex.hsl(color)[2];
  return lightness > 50;
}

export const accentColors = [
  colors.accentBlueDark,
  colors.accentGreen,
  colors.accentPeach,
  colors.accentPurple,
  colors.lightGreen,
  colors.lightPeach,
  colors.accentBlueMedium,
];

export const getAccentColorFromSeed = (seed: string) => {
  const index = Math.floor(seededRandom(seed) * accentColors.length);
  return accentColors[index];
};
